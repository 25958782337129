// Orders.js
import React from "react"
import { supabase } from "pages/Utility/supabase"
import DefaultLoading from "common/loading"
import { Button, Container, Row, Col, Card, CardBody } from "reactstrap"
import {
  OrderId, Title, Total, Date, StartDate, RantelType,
  EndDate, Owner, Financial,
} from "../Dashboard-Orders/LatestRequestsCol"
import { useEffect, useState } from "react"
import { TABLES } from "pages/Utility/constants"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import TableContainerPagination from "components/Common/TableContainerPagination"
import fetchPaginatedData from "utils/fetchPaginatedData"

const Orders = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [status, setStatus] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
    canNextPage: false,
    canPreviousPage: false
  })

  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  }
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  document.title = "Orders | HangarDirect"

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />
      },
    },
    {
      Header: "Customer",
      accessor: "full_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Title {...cellProps} />
      },
    },
    {
      Header: "Order Date",
      accessor: "created_at",
      disableFilters: true,
      Cell: cellProps => {
        return <Date {...cellProps} />
      },
    },
    {
      Header: "Rental Type",
      accessor: "rental_type",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <RantelType {...cellProps} />
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <Owner data={data} status={status} {...cellProps} />
      },
    },
    {
      Header: "Start Date",
      accessor: "checkIn",
      Cell: cellProps => {
        return <StartDate {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "End Date",
      accessor: "checkOut",
      Cell: cellProps => {
        return <EndDate {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "Payment Method Type",
      accessor: "payment_method_type",
      Cell: cellProps => {
        return cellProps?.value || ""
      },
      disableFilters: true,
    },
    {
      Header: "Financial Status",
      accessor: "financial_status",
      Cell: cellProps => {
        return <Financial {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "Total",
      accessor: "total_Amount",
      Cell: cellProps => {
        return <Total {...cellProps} />
      },
      disableFilters: true,
    },
    {
      Header: "View Details",
      disableFilters: true,
      accessor: row => {
        return (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => {
              history.push(`/invoices-detail/${row.id}`)
            }}
          >
            View Details
          </Button>
        )
      },
    },
  ]

  const getOrdersData = async (parameter) => {
    try {
      setLoading(true);
      let defaultFilters = [];

      if (parameter?.filter?.length) {
        const result = await supabase
          .from(TABLES.TRANSACTIONS)
          .select('*, user_id!inner(*)', { count: 'exact' })
          // .or(
          //   `"rentalType".ilike.%${parameter.filter}%,email.ilike.%${parameter.filter}%`,
            
          // )
          .or(
            `meta->>first_name.ilike.%${parameter.filter}%,meta->>last_name.ilike.%${parameter.filter}%`,
            { referencedTable: "user_id" }
          )
          .range(
            (parameter?.page - 1) * (parameter?.pageSize || 10),
            (parameter?.page * (parameter?.pageSize || 10)) - 1
          )
          .order('created_at', { ascending: false });

        if (result.error) {
          toast.error(result.error.message);
          return;
        }

        const { data, count } = result;

        if (data?.length) {
          const updatedData = data.map(item => {
            const user_data = { ...item?.user_id, ...item?.user_id?.meta }
            return {
              ...item,
              user_id: user_data,
              full_name: user_data?.first_name + " " + user_data?.last_name,
            }
          });

          setData(updatedData);
          setPagination({
            currentPage: parameter?.page || 1,
            pageSize: parameter?.pageSize || 10,
            totalCount: count || 0,
            totalPages: Math.ceil((count || 0) / (parameter?.pageSize || 10)),
            canPreviousPage: (parameter?.page || 1) > 1,
            canNextPage: (parameter?.page || 1) < Math.ceil((count || 0) / (parameter?.pageSize || 10))
          });
        } else {
          setData([]);
          setPagination({
            ...pagination,
            totalCount: 0,
            totalPages: 0,
            canNextPage: false,
            canPreviousPage: false
          });
        }
        setLoading(false);
        return;
      }

      const result = await fetchPaginatedData({
        table: TABLES.TRANSACTIONS,
        select: '*,user_id(*)',
        page: parameter?.page || pagination?.currentPage || 1,
        pageSize: parameter?.pageSize || pagination?.pageSize || 10,
        orderBy: 'created_at',
        ascending: false,
        filters: defaultFilters,
      });

      if (result.data?.length) {
        const updatedData = result.data.map(item => {
          const user_data = { ...item?.user_id, ...item?.user_id?.meta }
          return {
            ...item,
            user_id: user_data,
            full_name: user_data?.first_name + " " + user_data?.last_name,
          }
        });
        setData(updatedData);
        setPagination({
          ...pagination,
          ...result.pagination,
          canPreviousPage: result.pagination.currentPage > 1,
          canNextPage: result.pagination.currentPage < result.pagination.totalPages
        });
      } else {
        setData([]);
        setPagination({
          ...pagination,
          totalCount: 0,
          totalPages: 0,
          canNextPage: false,
          canPreviousPage: false
        });
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
      toast.error(error.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatuses = async () => {
    try {
      let { data, error } = await supabase.from(TABLES.STATUS).select("*")
      if (error) throw error
      if (data) {
        setStatus(data)
      }
    } catch (error) {
      console.error("Error fetching statuses:", error.message)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getOrdersData()
    fetchStatuses()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content position-relative" style={{ minHeight: "400px" }}>
        <Container fluid>
          {alert.type && (
            <Alerts
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              clear={() => setAlert(DEFAULT_ALERT_VALUES)}
            />
          )}

          {loading && <DefaultLoading style={{ position: "absolute", width: "90%", height: "100%", minHeight: "400px" }} />}

          <div className="mb-4 h4 card-title">Orders</div>
          <Card>
            <CardBody>
              <TableContainerPagination
                columns={columns}
                data={data}
                isSortBy={false}
                isGlobalFilter={true}
                isAddUserList={false}
                isFilter={false}
                customPageSize={10}
                pagination={pagination}
                refetchData={getOrdersData}
                className="custom-header-css"
                rowClass="cursor-auto-imp"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Orders