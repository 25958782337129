import moment from "moment"
import { convertToDecimal } from "pages/Utility/helper"
import React, { memo } from "react"

const Detail = ({ invoiceDetail, refundedData, subscriptionData = [], DYNAMIC_CHECK, isUserPlane }) => {
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined || isNaN(amount)) return '$0.00'
    const numericAmount = parseFloat(amount)
    return isNaN(numericAmount) ? '$0.00' : `$${numericAmount.toFixed(2)}`
  }

  const calculateItemPrice = () => {
    if (!invoiceDetail?.hangarData) return '0.00'

    // Check if we should use dynamic pricing
    if (DYNAMIC_CHECK && isUserPlane?.name === invoiceDetail?.aircraft_name) {
      const wingspan_length_decimal = convertToDecimal(isUserPlane?.inches_area);

      if (invoiceDetail.rentalType === 'monthly') {
        const monthlyPrice = invoiceDetail.hangarData.price_per_month * wingspan_length_decimal;
        return monthlyPrice.toFixed(2);
      }

      const dailyPrice = invoiceDetail.hangarData.price_per_night * wingspan_length_decimal;
      const days = parseInt(invoiceDetail.days || 0);
      return (dailyPrice * days).toFixed(2);
    }

    // Regular pricing if not dynamic
    if (invoiceDetail.rentalType === 'monthly') {
      return parseFloat(invoiceDetail.hangarData.price_per_month || 0).toFixed(2);
    }

    const pricePerNight = parseFloat(invoiceDetail.hangarData.price_per_night || 0);
    const days = parseInt(invoiceDetail.days || 0);
    return (pricePerNight * days).toFixed(2);
  }

  const DetailRow = ({ label, value, customStyle = {}, valueClass = "" }) => (
    <div className="container pb-3">
      <div className="border-bottom pb-2">
        <div className="d-flex justify-content-between px-2">
          <div style={{ fontWeight: "600" }}>{label}</div>
          <div style={{ fontWeight: "500", ...customStyle }} className={valueClass}>
            {value}
          </div>
        </div>
      </div>
    </div>
  )

  const PaymentStatusBadge = ({ status }) => (
    <div
      className="bg-success text-capitalize bg-opacity-10 px-1 rounded-2"
      style={{
        color: "#24902F",
        fontWeight: "500"
      }}
    >
      {status}
    </div>
  )

  return (
    <div>
      <div className="d-flex gap-3">
        {/* Order Details Section */}
        <div className="bg-white px-1 rounded mt-4 w-100">
          <div className="container pt-3">
            <Heading text="Order Details" customClass="m-0 px-0 pt-0" />

            <DetailRow
              label="Customer email"
              value={invoiceDetail?.email || 'N/A'}
            />

            <DetailRow
              label="Order by"
              value={<PaymentStatusBadge status="Customer" />}
            />

            <DetailRow
              label="Platform"
              value="web"
            />

            <DetailRow
              label="Payment method"
              value={invoiceDetail?.paymentMethod || 'N/A'}
            />

            <DetailRow
              label="Payment Method Type"
              value={<PaymentStatusBadge status={invoiceDetail?.payment_method_type || 'N/A'} />}
            />

            <DetailRow
              label="Financial status"
              value={<PaymentStatusBadge status={invoiceDetail?.paymentStatus || 'N/A'} />}
            />

            <DetailRow
              label="Order date"
              value={invoiceDetail?.created_at ?
                moment(invoiceDetail.created_at).format("DD MMM YYYY") :
                'N/A'
              }
            />

            <DetailRow
              label="Aircraft Name"
              value={invoiceDetail?.aircraft_name || "NOT_PROVIDED"}
            />
          </div>
        </div>

        {/* Payment Details Section */}
        <div className="bg-white px-1 rounded mt-4 w-100">
          <div className="container pt-3">
            <Heading text="Payment details" customClass="m-0 px-0 pt-0" />

            {DYNAMIC_CHECK && <>
              <DetailRow
                label="Item price"
                value={`$${invoiceDetail?.rentalType === 'monthly' ? invoiceDetail?.hangarData?.price_per_month : invoiceDetail?.hangarData?.price_per_night} per sq ft`}
              />
              <DetailRow
                label="Total area"
                value={(
                  `${(convertToDecimal(isUserPlane?.inches_area)).toFixed(2)} sq ft`
                )}
              />
            </>}
            <DetailRow
              label="Total item price"
              value={formatCurrency(calculateItemPrice())}
            />

            <DetailRow
              label="Discount"
              value={formatCurrency(0)}
            />

            <DetailRow
              label="Promotion"
              value={formatCurrency(0)}
            />

            <DetailRow
              label="Subtotal"
              value={formatCurrency(calculateItemPrice())}
            />

            <DetailRow
              label="Total tax"
              value={formatCurrency(invoiceDetail?.tax)}
            />

            <DetailRow
              label="Service fee"
              value={formatCurrency(invoiceDetail?.serviceFee)}
            />
            {!!invoiceDetail?.premium_amenities_amount &&
              <DetailRow
                label="Premium amenities"
                value={formatCurrency(invoiceDetail?.premium_amenities_amount)}
              />
            }

            {refundedData && (
              <DetailRow
                label="Refunded Amount"
                value={formatCurrency(refundedData?.refund_amount)}
              />
            )}

            <DetailRow
              label="Grand total"
              value={formatCurrency(
                (parseFloat(invoiceDetail?.total_Amount || 0) +
                  parseFloat(invoiceDetail?.credits || 0))
              )}
            />

            <DetailRow
              label="Credits"
              value={formatCurrency(invoiceDetail?.credits)}
              customStyle={{ color: 'red' }}
            />

            <DetailRow
              label="Paid by customer"
              value={formatCurrency(invoiceDetail?.total_Amount)}
            />
          </div>
        </div>
      </div>

      {subscriptionData.length > 0 && (
        <div className="d-flex gap-3">
          <div className="bg-white px-1 rounded mt-4 w-100">
            <div className="container pt-3">
              <Heading text="Subscription Timeline" customClass="m-0 px-0 pt-0" />
              <div className="pb-2 bg-white rounded">
                {subscriptionData.map((subscription, index) => (
                  <div key={index} className="d-flex border-bottom py-2 flex-column">
                    <div className="d-flex justify-content-between">
                      <div>
                        <strong>Start Date:</strong>{' '}
                        {moment(subscription.start_date).format('MMM DD, YYYY')}
                      </div>
                      <div>
                        <strong>End Date:</strong>{' '}
                        {moment(subscription.end_date).format('MMM DD, YYYY')}
                      </div>
                      <div>
                        <strong>Amount:</strong> ${subscription.amount}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(Detail)